import baseApi from "@/api/baseApi";
import requests from "@/utils/axios";

export default {
    ...baseApi,
    // 模块URL
    modulesUrl: "/aftersale/ticket/works/yto",
    // 匹配设备
    setProject(query: any) {
        return requests.post(this.modulesUrl + "/setProject", query);
    },
    // 发起工单
    workSend(query: any) {
        return requests.post(this.modulesUrl + "/workSend", query);
    },
    // 反馈工单
    workOver(query: any) {
        return requests.post(this.modulesUrl + "/workOver", query);
    },
};
