import { Options } from "vue-class-component";
import BaseTableInterface from "@/mixins/BaseTableInterface";
import BaseTableMixins from "@/mixins/BaseTable";
import UserAPI from "@/api/system/user1";
import API from "@/api/aftersale/ticket/works/yto";
import SelectLevel from "@/modules/select-level/select-level.vue";
import WorksYtoTableItem from "@/entity/aftersale/ticket/WorksYtoTableItem";

@Options({
    name: "app-aftersale-ticket-works-yto",
    components: {
        "app-select-level": SelectLevel,
    },
})
export default class Yto extends BaseTableMixins<WorksYtoTableItem> implements Partial<BaseTableInterface<WorksYtoTableItem>> {
    public created(): void {
        this.setInterface(this);
        this.tableRequestItem.lockItem = this.getLockItem();
        this.editTableItem = new WorksYtoTableItem();
        this.editModalItem = new WorksYtoTableItem();
    }

    //锁定列数据实体
    public getLockItem(): any {
        return {
            userCode: {
                type: "eq",
                name: "user_code",
                title: "所属用户",
                parent: this.authUser.orgCode,
                value: null,
                openFn: this.userCodeLevelModalOpen,
            },
        };
    }

    public activated(): void {
        this.getList(1);
    }

    //后端API
    public getApi(): any {
        return API;
    }

    //UserAPI
    get getUserApi(): any {
        return UserAPI;
    }

    // //列表回调
    // public listCallback(items: Array<WorksYtoTableItem>): void {
    //     return;
    // }

    //添加弹窗配置
    public addModalSetting(): void {
        this.editTableItem = new WorksYtoTableItem();
        this.editModalItem = new WorksYtoTableItem();
        this.editModalItem.workYtoStatus = 1;
        this.editModalTitle = "添加工单";
        this.editModalSubmitButtonName = "添加";
    }
    //添加提交检测
    public addSubmitChecking(): string {
        if (this.$tools.isEmpty(this.editModalItem.reporterCode)) return "上报人工号不能为空！";
        if (this.$tools.isEmpty(this.editModalItem.reporterName)) return "上报人姓名不能为空！";
        if (this.$tools.isEmpty(this.editModalItem.reporterMobile)) return "上报人手机不能为空！";
        if (this.$tools.isEmpty(this.editModalItem.exceptionStartTime)) return "故障开始时间不能为空！";
        if (this.$tools.isEmpty(this.editModalItem.taskId)) return "任务ID不能为空！";
        if (this.$tools.isEmpty(this.editModalItem.workOrderNo)) return "工单号不能为空！";
        if (this.$tools.isEmpty(this.editModalItem.assetCode) && this.$tools.isEmpty(this.editModalItem.deviceCode)) return "资产编码和设备编码不能同时为空！";
        if (this.$tools.isEmpty(this.editModalItem.urgency)) return "紧急程度不能为空！";
        if (this.$tools.isEmpty(this.editModalItem.deviceType)) return "设备类型不能为空！";
        if (this.$tools.isEmpty(this.editModalItem.operatingArea)) return "操作区域不能为空！";
        if (this.$tools.isEmpty(this.editModalItem.exceptionDesc)) return "异常描述不能为空！";
        return null;
    }
    // //添加回调
    // public addModalCallback(res: any): void {
    //     return;
    // }

    //userCode搜索字段
    private userCodeLevelSearchField: any = [
        { field: "user_code", name: "工号" },
        { field: "real_name", name: "姓名" },
    ];
    //userCode层级选择器打开
    public userCodeLevelModalOpen() {
        (this.$refs.userCodeLevel as any).openModal();
    }
    //userCode层级选择器提交
    public async userCodeLevelFunction(item: any): Promise<void> {
        const curItem = this.$tools.isEmpty(item.value) ? this.getLockItem().userCode : item;
        this.tableRequestItem.lockItem.userCode.value = curItem.value;
        this.tableRequestItem.lockItem.userCode.title = curItem.title;
        this.tableRequestItem.lockItem.userCode.parent = curItem.parent;
        await this.getList(1);
        return Promise.resolve();
    }

    //发起工单
    public workSend(item: any) {
        const statusAlertConfig = {
            title: "发起工单",
            message: "请确认重新发起工单？",
            closeButtonName: "取消",
            submitButtonName: "提交",
        };

        this.$vueAlert(statusAlertConfig)
            .then(async () => {
                this.isLoading = true;
                await this.API.workSend(item)
                    .then((res: any) => {
                        this.toast.success(res.msg);
                        this.getList(0);
                    })
                    .finally(() => {
                        this.isLoading = false;
                    });
            })
            .catch(() => {
                this.isLoading = false;
            });
    }

    get userCodeSelectApi() {
        return {
            api: (query: any) => UserAPI.idSelect(query),
            parent: "org_code",
            status: "user_status",
            name: "real_name",
            id: "user_code",
            cid: this.editModalItem.belongOrgCode,
        };
    }
}
